import apiService from "@/services/apiService";
import SkillBaseCard from "@/components/card/SkillBaseCard.vue";

export default {
    setup() { },

    components: {
        SkillBaseCard,
    },

    props: {},

    watch: {
        '$route'() {
            this.retrieveList()
        },
        search(val) {
            if (this.loading) return;
            this.currentPage = 1;
            if (val == null || val.trim() == "") return this.$router.push({ name: this.$route.name, replace: true }).catch(() => {})
            this.$router.push({ name: this.$route.name, query: { search: val.trim(), filter: this.selectedFilter.value.trim() }, replace: true }).catch(() => {})
        },
        selectedFilter(val) {
            if (this.loading) return;
            this.currentPage = 1;
            const query = this.search == null ? { filter: val.value } : { search: this.search.trim(), filter: val.value.trim() };
            this.$router.push({ name: this.$route.name, query, replace: true }).catch(() => {})
        },
    },

    data() {
        return {
            loading: true,
            showTrash: false,
            items: [],
            filters: [
                { label: 'Trier de A à Z', value: 'asc' },
                { label: 'Trier de Z à A', value: 'desc' },
                { label: 'Trier par : Plus récent', value: 'recent' },
            ],
            search: this.$route.query.search ?? null,
            selectedFilter: { label: 'Trier de A à Z', value: 'asc' },
            total: 0,
            currentPage: 1,
            lastPage: 1,
        }
    },

    created() { 
        const selected = this.filters.find(el => el.value == this.$route.query.filter)
        this.selectedFilter = selected ? selected : { label: 'Trier de A à Z', value: 'asc' };
        this.retrieveList();
    },

    mounted() { },

    methods: {
        toggleTrash() {
            this.showTrash = !this.showTrash
            this.retrieveList();
        },
        async retrieveList() {
            this.loading = true
            try {
                const queries = [];
                if (this.currentPage != 1) queries.push(`page=${this.currentPage}`)
                if (this.search != null && this.search.trim() != "") queries.push(`search=${this.search}`)
                if (this.selectedFilter.value != null) queries.push(`filter=${this.selectedFilter.value}`)

                let queryString = queries.join('&');
                if(this.showTrash) queryString += '&target=trash'
                const url = `/skill-bases?${queryString}`

                const request = await apiService.get(url)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.items = response.data;
                this.total = response.total;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
            } catch (error) {
                this.items = []
            } finally {
                this.loading = false;
            }
        },
        changePage() {
            this.retrieveList();
        }
    }
}
// import { useAuthStore } from "@/stores/AuthStore";

export default {
    setup() {

    },

    components: {

    },

    props: {
        id: {
            default: 0,
        },
        label: {
            default: '--'
        },
        description: {
            default: '--'
        },
        createdBy: {
            default: '--'
        },
        createdAt: {
            default: null
        },
        isEnabled: {
            default: true,
        },
        competenceCount: {
            default: 0
        },
        canDelete: {
            default: false
        },
        showTrash: {
            default: false
        }
    },

    watch: {

    },

    data() {
        return {

        }
    },

    created() {
    },

    mounted() {

    },

    methods: {
        maxCharactere(desc) {
            if (desc.length > 80) desc = desc.slice(0, 80) + "..."
            return desc
        },
        goToDetail() {
            // const authStore = useAuthStore();
            if (this.showTrash) {
                return this.$router.push({
                    name: 'evaluationModelForm',
                    params: { id: this.id, },
                    query: { archived: true }
                })
            }
            return this.$router.push({
                name: 'evaluationModelForm',
                params: {
                    id: this.id,
                },
            })
        },
    },
}
import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";
import { useAuthStore } from "@/stores/AuthStore";

export default {
    setup() {

    },

    components: {

    },

    props: {
        dialog: {
            default: false,
        },
        titleCard: {
            default: "---"
        },
        isLearner: {
            default: false,
        },
        userId: {
            default: null,
        },
        userLastname: {
            default: null,
        },
        userFirstname: {
            default: null,
        },
        userEmail: {
            default: null,
        }, 
        userPromotion: {
            default: null
        },
        userRole: {
            default :null
        }
    },

    watch: {
        dialog(val) {
            if (val) {

                this.lastname = this.userLastname;
                this.firstname = this.userFirstname;
                this.email = this.userEmail;
                if(!this.isLearner) {
                    this.promotion = this.userPromotion != null ? this.userPromotion: null
                }else{
                    this.promotion = this.userPromotion != null ? this.userPromotion.promotion : null
                }
            }
        }
    },

    data() {
        return {
            valid: true,
            loading: false,
            lastname: null,
            firstname: null,
            email: null,
            role: null,
            organization: null,
            promotion: this.userPromotion,
            promotions : [],
            rules: {
                required: value => !!value || 'Champs requis',
                email: v => /.+@.+\..+/.test(v) || 'Adresse e-mail est invalide',
            },
        }
    },

    async created() {
        const authStore = useAuthStore();
        this.role = authStore.info.role;
        this.organization = this.role != "superadmin" ? authStore.info.organization : null
        //this.promotions = this.promotion != null ? [this.promotion] : []
       await this.retrievePromotionList()
    },

    mounted() {

    },

    methods: {
        close() {
            this.loading = false;
            this.$refs.form.reset();
            this.$emit('update:dialog', false)
        },

        async retrievePromotionList(){
            try {
                const request = await apiService.get(`/organizations/promotion/${this.$route.params.id ?? this.organization.id }?target=all`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.promotions = response;
            } catch (error) {
                this.promotions = [];
            }
        }, 
        async actionHandler() {
            this.loading = true;
            try {
                const authStore = useAuthStore();
                await this.$refs.form.validate();
                if (!this.valid) return;

                const urlPart = this.isLearner ? 'type=learner' : 'type=tutor'
                const request = await apiService.post(`/organizations/${this.$route.params.id ?? authStore.info.organization.id}/members/${this.userId}?${urlPart}`, {
                    lastname: this.lastname,
                    firstname: this.firstname,
                    email: this.email,
                    promotionId :  this.promotion 
                })
          
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false })
                this.$emit("refresh");
                this.close();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        }
    },
}
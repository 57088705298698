import apiService from "@/services/apiService";
import { useAuthStore } from "@/stores/AuthStore";
import { EventBus } from "@/evt-bus";
import { JitsiMeet } from '@mycure/vue-jitsi-meet';

export default {
    setup() { },

    components: {
        VueJitsiMeet: JitsiMeet
    },

    props: {
        meetingRoom: {
            default: ""
        }
    },

    computed: {
        jitsiOptions() {
            const authStore = useAuthStore();

            return {
                debug: true,
                roomName: this.meetingRoom,
                noSSL: false,
                jwt: this.jwtAuth,
                userInfo: {
                    displayName: `${authStore.info.firstname} ${authStore.info.lastname}`,
                    email: authStore.info.email,
                },
                configOverwrite: {
                    enableNoisyMicDetection: false
                },
                interfaceConfigOverwrite: {
                    SHOW_JITSI_WATERMARK: false,
                    SHOW_WATERMARK_FOR_GUESTS: false,
                    SHOW_CHROME_EXTENSION_BANNER: false
                },
                onload: this.onIFrameLoad,
            };
        },
    },

    watch: {

    },

    data() {
        return {
            showMeeting: false,
            domain: process.env.VUE_APP_JITSI_URL,
            jwtAuth: "",
            width: "400px",
            height: "300px",
        }
    },

    created() {

    },

    mounted() {

    },

    methods: {
        async setHistory(eventName) {
            this.showMeeting = eventName == 'JOIN'
            try {
                const request = await apiService.post(`/meetings`, { meetingRoom: this.meetingRoom, event: eventName })
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                if(eventName == 'JOIN'){
                    this.jwtAuth = response.jitsiToken
                }
                this.$emit("setMeeting", this.showMeeting);
            } catch (error) {
                this.showMeeting = false
                this.$emit("setMeeting", this.showMeeting);
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
        onIFrameLoad() {
            // do stuff
        },
        toogleFullScreen() {
            if(this.width != '400px') {
                this.width  = "400px"
                this.height  = "300px"
            } else {
                this.width = "88vw"
                this.height = "73vh"
            }
        }
    }
}
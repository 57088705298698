import { EventBus } from "@/evt-bus";
import { useAuthStore } from "@/stores/AuthStore";

import apiService from "@/services/apiService";
import RestoreOrDeleteDialog from "@/components/dialog/RestoreOrDeleteDialog.vue"
import AssignSkillBaseDialog from "@/components/dialog/AssignSkillBaseDialog.vue"
import ReferenceLibraryCard from "@/components/card/ReferenceLibraryCard.vue";
import RemoveSkillBaseFromReferenceDialog from "@/components/dialog/RemoveSkillBaseFromReferenceDialog.vue";

export default {
    setup() {

    },

    components: {
        RestoreOrDeleteDialog,
        AssignSkillBaseDialog,
        ReferenceLibraryCard,
        RemoveSkillBaseFromReferenceDialog,
    },

    props: {},     
    watch: {
        '$route'() {
            this.$router.go(0)
        },
        defaultDisable(val) {
            if(!val) this.retrieveAvailableorganizations()
        }
    },

    data() {
        return {
            valid: true,
            loading: false,
            loadingBtn: false,
            role: '',

            tabs: null,
            label: null,
            descriptions: null,
            owner: null,
            organization: null,
            organizations: [],
            skillBases: [],
            backupSkillBases: [],
            selectedOrganizations: [],
            userOrganization: {},

            isEnabled: true,
            isArchived: false,
            isValided: false,
            rules: { required: value => !!value || 'Champs requis', },
            defaultDisable: this.$route.params.id ? true : false,
            isEditing: this.$route.params.id ? true : false,
            isVertical: true,

            dialogSkillBaseToDelete: false,
            selectedSkillBaseToDelete: null,
        }
    },

    created() {
        const authStore = useAuthStore();
        this.role = authStore.info.role;
        this.userOrganization = authStore.info.organization;

        if (this.$route.params.id) {
            this.retrieveSingle();
        }

        if (this.role == "admin" || this.role == 'superadmin'){
            this.retrieveAvailableorganizations()
        }

    },

    mounted() { },

    methods: {
        goBack() {
            this.$router.go(-1)
        },
        confirmed(selected) {
            this.backupSkillBases = selected;
        },
        selected(selected) {
            this.skillBases = selected;
        },
        canceled() {
            this.skillBases = this.backupSkillBases;
        },
        openDeleteSkillBaseDialog(skillBase) {
            this.selectedSkillBaseToDelete = skillBase[2]
            this.dialogSkillBaseToDelete = true;
        },
        resetSelected() {
            this.selectedSkillBaseToDelete = null;
        },
        setEditButtonEnable(){
            if (this.role == "admin" || this.role == "referent" || this.role == 'superadmin'){
                this.defaultDisable = !this.defaultDisable;
            }
        },
        removeElement() {
            this.skillBases = this.skillBases.filter(el => el.id != this.selectedSkillBaseToDelete)  
            setTimeout(() => {
                if (this.skillBases.length <= 0) this.tabs = null;
                else this.tabs = 0;
            }, 10);
        },
        maxCharactere(desc) {
            if (desc.length > 18) desc = desc.slice(0, 18) + "..."
            return desc
        },
        organizationSelectDisplay(item) {
            return `${item.label}`
        },
        refreshAvailableorganizations(){
            this.retrieveAvailableorganizations()
        },
        async retrieveAvailableorganizations() {
            try {
                const url = `/organizations?target=all&type=reference_library`
                const request  = await apiService.get(url)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.organizations = response.data
            } catch (error) {
                this.organizations = []
            }
        },
        async retrieveSingle() {
            this.loading = true;
            try {
                let target = ""
                if (this.$route.query.archived) target = "?target=trash"
                const request = await apiService.get(`/reference-libraries/${this.$route.params.id}${target}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.label = response.data.label
           
                let assignedOrganizations = [];
                for (let i = 0; i < response.data.organizations.length; i++) {
                    const element = response.data.organizations[i].organization;
                    assignedOrganizations.push(element)
                }

                this.selectedOrganizations = assignedOrganizations;

                this.skillBases = [];
                for (let i = 0; i < response.data.skillBases.length; i++) {
                    const element = response.data.skillBases[i].skillBase;
                    this.skillBases.push(element)
                }

                this.backupSkillBases = this.skillBases;
            } catch (error) {
                this.goBack();
            } finally {
                this.loading = false;
            }
        },
        async actionHandler() {
            this.loadingBtn = true
            try {
                await this.$refs.form.validate();
                if (!this.valid) return;

                const selectedSkillBases = [];
                for (let i = 0; i < this.skillBases.length; i++) {
                    const element = this.skillBases[i];
                    selectedSkillBases.push(element.id);
                }

                const assignedOrganizations = [];
            
                if (this.role == "admin" || this.role == 'superadmin'){
                    for (let i = 0; i < this.selectedOrganizations.length; i++) {
                        assignedOrganizations.push(this.selectedOrganizations[i].id);
                    }
                } else if (this.role == "referent"){
                    assignedOrganizations.push(this.userOrganization.id);
                }

                let payload = { label: this.label, skillBases: selectedSkillBases, organizations: assignedOrganizations, current_organization_id : this.userOrganization.id ?? localStorage.getItem('organizationId') }

                const url = this.$route.params.id ? `/reference-libraries/${this.$route.params.id}` : `/reference-libraries`
                const request = await apiService.post(url, payload)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })

                if (!this.$route.params.id) {
                    this.$refs.form.reset();
                    this.skillBases = [];
                    this.backupSkillBases = [];
                } else {
                    this.defaultDisable = true;
                }
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingBtn = false
            }
        },
   
     
    }
}
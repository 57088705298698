import apiService from "@/services/apiService";
import { formMixin } from '@/utils/formMixin';
import { utilMixin } from '@/utils/utilMixin';

export default {
    setup() {

    },

    components: {

    },
    mixins: [formMixin, utilMixin],

    props: {
        currentSelected: {
            default() {
                return [];
            }
        },
        id: {
            default() {
                return 0;
            }
        },
        canEdit: {
            default() {
                return false;
            }
        }
    },

    watch: {
        async dialog(val) {
            if (val) {
                await this.retrieveFormTemplates();
            }
        },
        search(val) {
            const filtered = this.items.filter(el => el.label.includes(val));
            if (filtered.length == 0) {
                this.addForm = true;
            } else {
                this.addForm = false;
            }

            if (val == null || val.trim() == "") {
                this.addForm = false;
                this.showForm = false;
            } else {
                this.label = val
            }
        }
    },
    computed: {
        highlightLink() {
            if (this.isListView) {
                return {
                    color: 'black',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                };
            } else {
                return {
                    color: 'black',
                };
            }

        },
    },
    data() {
        return {
            dialog: false,
            valid: true,
            loading: false,
            search: '',
            headers: [
                { text: 'Libellé', align: 'start', sortable: false, value: 'label', },
                { text: 'Actions', value: 'actions' },
            ],
            items: [],
            backupItems: [],  // Backup storage for items

            label: null,
            addForm: true,
            isPreview: false,
            rules: { required: value => !!value || 'Champs requis', },
            selectedLabel: ""
        }
    },

    async created() {
    },

    mounted() {

    },

    methods: {
        close() {
            this.restoreItemsState(); // Restore items when dialog is closed
            this.$emit('canceled'); // Emit canceled event
            this.dialog = false;
        },
        toggleView(isPreview) {
            this.isPreview = isPreview;
        },
        removeSelected() {
            this.currentSelected = null; // Logic to remove the selected chip
            this.$emit("selected", null);
        },
        refreshFormTemplates() {
            this.retrieveFormTemplates();
        },
        toogleSelected(item) {
            this.items.forEach(el => {
                if (el !== item) {
                    this.$set(el, 'selected', false);
                }
            });

            this.$set(item, 'selected', !item.selected);
            const selected = this.items.filter(el => el.selected);

            if (!item.selected) {
                this.restoreItemsState(); // Restore items if selection is canceled
                this.$emit("selected", null);
            } else {
                this.backupItemsState(); // Backup items on new selection
                this.$emit("selected", selected);
            }
        },
        async retrieveFormTemplates() {
            try {
                this.items = [];
                this.backupItems = [];  // Clear backup when retrieving new items
                const request = await apiService.get(`/forms?target=assignation`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                this.items = response.data.filter(item => item.competence_id == this.id && item.competence_id != null);
                this.backupItems = JSON.parse(JSON.stringify(this.items));  // Backup current items

                if (this.items.length == 0) {
                    this.addForm = true;
                }
                let current = {};

                if (this.currentSelected != null && this.currentSelected != []) {
                    current = this.items.find(item => item.id === this.currentSelected.id)
                    this.selectedLabel = current.label
                    this.$set(current, 'selected', true);
                }

                // Trier les éléments en plaçant l'élément sélectionné en premier
                this.items = this.items.sort((a, b) => {
                    if (a.selected) return -1;
                    if (b.selected) return 1;
                    return 0;
                });

            } catch (error) {
                this.items = [];
            }
        },
        customFilter(value, search) {
            const filtered = this.items.filter(el => el.label.includes(search));
            if (filtered.length == 0) this.addForm = true;
            else this.addForm = false;

            return value != null &&
                search != null &&
                typeof value === 'string' &&
                value.toString().indexOf(search) !== -1
        },
        actionHandler() {
            const selected = this.items.filter(el => el.selected);
            if (selected.length == 0) {
                this.$emit("confirmed", null);
            } else {
                this.dialog = false;
                this.$emit("confirmed", selected[0]);
            }
        },
        goToCreateCompetenceFormTemplateView() {
            const url = this.$router.resolve({ name: "competenceFormTemplate", params: { competenceId: this.id } }).href;
            window.open(url, '_blank');
        },
        // Backup the current state of items
        backupItemsState() {
            this.backupItems = JSON.parse(JSON.stringify(this.items));
        },
        // Restore the items from the backup
        restoreItemsState() {
            this.items = JSON.parse(JSON.stringify(this.backupItems));
        },
    },
}

import UserInfoCard from "@/components/card/UserInfoCard.vue";
import PromotionInfoCard from "@/components/card/PromotionInfoCard.vue";
import CreatePromotionsDialog from "@/components/dialog/CreatePromotionsDialog.vue"
import UpdatePromotionsDialog from "@/components/dialog/UpdatePromotionsDialog.vue"
import DisablePromotionDialog from "@/components/dialog/DisablePromotionDialog.vue"
import RestorePromotionDialog from "@/components/dialog/RestorePromotionDialog.vue";
import AssignPromotionLearnersDialog from "@/components/dialog/AssignPromotionLearnersDialog.vue"
import AssignPromotionSkillBasesDialog from "@/components/dialog/AssignPromotionSkillBasesDialog.vue"
import apiService from "@/services/apiService";
import { EventBus } from "@/evt-bus";
import { useAuthStore } from "@/stores/AuthStore";

export default {
    setup() {

    },

    components: {
        UserInfoCard,
        PromotionInfoCard,
        CreatePromotionsDialog,
        UpdatePromotionsDialog,
        DisablePromotionDialog,
        RestorePromotionDialog,
        AssignPromotionLearnersDialog,
        AssignPromotionSkillBasesDialog
    },

    props: {
        isArchived: {
            default: false,
        },
    },

    watch: {
        search(val) {
            if (val == null || val.trim() == "") this.retrievePromotions();
            if (this.loading) return;
            this.retrievePromotions();
        },
    },

    data() {
        return {
            total: 0,
            items: [],
            lastPage: 1,
            search: null,
            currentPage: 1,
            loading: true,
            importUserDialog: false,
            updatePromotionDialog: false,
            disableUserDialog: false,
            restoreUserDialog: false,
            assignLearnersDialog: false,
            isRestoring: false,
            showTrash: false,
            role: '',
            organization: '',
            selectedPromotion: {},
            selectedPromotionInfo: {},
            componentKey: 0,
            assignSkillBaseDialog: false
        }
    },

    async created() {
        const authStore = useAuthStore();
        this.role = authStore.info.role;
        this.organization = authStore.info.organization ?? null;
        
        EventBus.$on('refreshPromotions', () => {
            this.retrievePromotions();
        })

        await this.retrievePromotions();
    },

    mounted() {
    },

    methods: {
        toggleTrash() {
            this.showTrash = !this.showTrash
            this.retrievePromotions();
        },
        async refreshComponent() {
            this.remountComponent();
            EventBus.$emit('refreshTutors');
            EventBus.$emit('refreshLearners');
            await this.retrievePromotions();
        },
        remountComponent() {
            this.componentKey += 1; // Changing the key forces Vue to remount the component
        },
        async retrievePromotions() {
            this.loading = true;
            try {

                let target = this.showTrash ? "trash" : null;

                const url = (this.search == null || this.search.trim() == "")
                    ? `/organizations/promotion/${this.$route.params.id ?? this.organization.id}?page=${this.currentPage}` + (target != null ? `&target=${target}` : "")
                    : `/organizations/promotion/${this.$route.params.id ?? this.organization.id}?search=${this.search}&page=${this.currentPage}` + (target != null ? `&target=${target}` : "");

                const request = await apiService.get(url)
                const response = await request.json();

                if (!request.ok) throw new Error(response.message);

                this.items = response.data;
                this.total = response.total;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
            } catch (error) {
                this.items = [];
            } finally {
                this.loading = false;
            }
        },
        changePage() {
            this.retrievePromotions();
        },
        updatePromotions({ promotionId }) {
            const selected = this.items.find(el => el.id == promotionId)
            this.selectedPromotion = selected;
            this.updatePromotionDialog = true;
        },
        disablePromotions({ promotionId }) {
            const selected = this.items.find(el => el.id == promotionId)
            this.selectedPromotion = selected;
            this.disableUserDialog = true;
        },
        restorePromotions({ promotionId }) {
            const selected = this.items.find(el => el.id == promotionId)
            this.selectedPromotion = selected;
            this.restoreUserDialog = true;
            this.isRestoring = true;
        },
        deletePromotions({ promotionId }) {
            const selected = this.items.find(el => el.id == promotionId)
            this.selectedPromotion = selected;
            this.restoreUserDialog = true;
            this.isRestoring = false;
        },
        showUserEvaluations({ promotionId }) {
            const selected = this.items.find(el => el.id == promotionId)
            this.selectedPromotion = selected;
            const authStore = useAuthStore();
            if (authStore.info.role == 'admin' || authStore.info.role == 'superadmin') return this.$router.push({ name: "singleEvaluationProfil", params: { learnerId: selected.id, organizationId: this.$route.params.id } })
            if (authStore.info.role == 'tutor' || authStore.info.role == 'referent') return this.$router.push({ name: "evaluationProfil", params: { learnerId: selected.id } })
            return this.$router.push({ name: "singleEvaluation", params: { learnerId: selected.id } })
        },

        assignLearners({ promotionId }) {
            const selected = this.items
            .flatMap(el => el.learners) 
            .filter(learner => learner.user.promotion_id == promotionId) 
            .map(learner => learner.user);
        
            this.selectedPromotionInfo = this.items.find(el => el.id === promotionId) 
            this.selectedPromotion = selected;
            this.assignLearnersDialog = true;
        },
        assignSkillBase({ promotionId }) {
            const selected = this.items.find(el => el.id == promotionId)
            this.selectedPromotion = selected;
            this.assignSkillBaseDialog = true;
        }
    },
}
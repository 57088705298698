import { EventBus } from "@/evt-bus"
import apiService from "@/services/apiService"
import { useAuthStore } from "@/stores/AuthStore";

export default {
    setup() { },

    components: {

    },

    props: {
        dialog: {
            default: false,
        },
        currentPromotion:{
            default: null
        }
    },

    watch: {
        dialog(val) {
            if (val) {
                this.label = this.currentPromotion.label,
                this.tutor = this.currentPromotion.referent
            }
        }
    },

    data() {
        return {
            valid: true,
            loading: false,
            label: '',
            tutors: [],
            tutor: null, 
            rules: {
                required: value => !!value || 'Champs requis',
                email: v => /.+@.+\..+/.test(v) || 'Adresse e-mail est invalide',
            },
        }
    },

    created() {
        const authStore = useAuthStore();
        this.user = authStore.info;
        this.retrieveAvailableTutors();
    },

    mounted() { },

    methods: {
        close() {
            this.$refs.form.reset();
            this.dialog = false;
            this.$emit("update:dialog", false)
        },
        tutorSelectDisplay(item) {
            return `#${item.id} - ${item.lastname} ${item.firstname}`
        },
   
        async retrieveAvailableTutors() {
            try {
                const url = `/organizations/tutors`
                const request  = await apiService.get(url)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.tutors = response.data

                if (this.user.role == "superadmin"){
                    this.tutors = response.data.filter(tutor => tutor.organization.id == this.$route.params.id)
                }
            } catch (error) {
                this.tutors = []
            }
        },
        async actionHandler() {
            this.loading = true;
            try {
                await this.$refs.form.validate();
                if (!this.valid) return;
                const url = `/organizations/promotion/${this.currentPromotion.id}`;
                
                const dataToSend =  { 
                    label: this.label, 
                    tutorId : this.tutor.id,
                    organizationId: this.user.organization?.id || this.$route.params.id 
                }

                const request = await apiService.post(url, dataToSend)
                const response = await request.json();

                if (!request.ok) throw new Error(response.message);

                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$emit('refresh')
                this.close();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false
            }
        },
    }
}
export default {
    setup() { },

    components: {},

    props: {
        id: {
            default: 0
        },
        label: {
            default: ""
        },
        createdBy: {
            default: null
        },
        competenceCount: {
            default: 0
        },
        organization: {
            default:null
        },
        promotion: {
            default:null
        },
        isArchived: {
            default: false
        }
    },

    watch: {},

    data() {
        return {}
    },

    created() { 
    },

    mounted() { },

    methods: {
        goToDetail() {
            if (this.isArchived) return this.$router.push({ name: "skillsBasesForm", params: { id: this.id }, query: { archived: true } })
            return this.$router.push({ name: "skillsBasesForm", params: { id: this.id } })
        }
    }
}
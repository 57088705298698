import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";

export default {
    setup() {

    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        promotionId: {
            default: null
        },
    },

    watch: {

    },

    data() {
        return {
            loading: false
        }
    },

    created() {

    },

    mounted() {

    },

    methods: {
        close() {
            this.$emit("update:dialog", false)
        },
        async actionHandler() {
            this.loading = true

            try {
                const request = await apiService.put(`/organizations/promotion/${this.promotionId}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                EventBus.$emit('OPEN_SNACKBAR', { message: response.message, isError: false })
                this.$emit("refresh")
                this.close();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        }
    },
}
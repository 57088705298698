import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";

export default {
    setup() {},

    components: {},

    props: {
        roomName: {
            default: ""
        }
    },

    watch: {
        dialog(val) {
            if(val) this.retrieveMeetingRoomHistories()
        }
    },

    data() {
        return {
            loading: true,
            dialog: false,
            items: [],
            headers: [
                { text: 'Historiques', align: 'start', value: 'event', },
                { text: 'Dates', value: 'createdAt' },
            ],
        }
    },

    created() {

    },

    mounted() {

    },

    methods: {
        close() {
            this.dialog = false;
        },

        async retrieveMeetingRoomHistories() {
            this.items = [];
            this.loading = true;

            try {
                const request = await apiService.get(`/meetings/${this.roomName}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.items = response.histories
               
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })  
            } finally {
                this.loading = false;
            }
        }
    }
}
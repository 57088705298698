
import apiService from "@/services/apiService";
import { EventBus } from "@/evt-bus";
import { useAuthStore } from "@/stores/AuthStore";


// mixin for handling evaluation functionnalities
export const evaluationMixin = {
    data() {
        return {
            loading: true,
            loadingSummary: false,
            evaluation: null,
            evaluations: [],
            selectedEvaluation: {},

            infoRole: null,

            evalByTutor: [],
            evalByUser: [],
            compareEval: false,
            validCompetenceByTutor: 0,
            validCompetenceByLearner: 0,
            validCompetenceCount: 0,
            isCompleted: false,
            isEnabled: true,

            drawerHistory: null,
            drawerComments: null,
            drawerResources: null,
            drawerLearnerResources: null,
            drawerTutorResources: null,
            selectedCompetence: null,
            cover: null,
            items: [
                { title: 'Voir les commentaires', action: 'showComments', icon: 'mdi-message-reply-text', isLeft: true },
                { title: 'Historique', action: 'showHistory', icon: 'mdi-folder-outline' },
            ],
            skillBases: [],
            titlePart: null,
            toggleEnableDialog: false,
            showCompareBtn: false,
            selectedEvalCompetence: null,
            reEvaluateDialog: false,
            evalDialog: false,
            tutorEval: []


        };
    },
    methods: {
        setEvaluationAction(action, competence){
            if (action == "showComments"){
                return this.showComments(competence)
            }else if (action == "showHistory"){
                return this.showHistory(competence.evaluation)
            }
            return this.showResources(competence)
        },
        showComments(competence) {
            this.selectedCompetence = competence;
            this.drawerComments = true
        },
        showResources(competence) {
            this.selectedCompetence = competence;
            this.drawerResources = true;
        },
        showHistory(competence) {
            this.selectedCompetence = competence;
            this.drawerHistory = true;
        },
        updateCommentDrawer(val) {
            this.drawerComments = val;
            if (!val) this.selectedCompetence = null;
            EventBus.$emit('DRAWER_STATE', val);
        },
        updateResourceDrawer(val) {
            this.drawerResources = val;
            if (!val) this.selectedCompetence = null;
            EventBus.$emit('DRAWER_STATE', val);
        },
        updateHistoryDrawer(val) {
            this.drawerHistory = val;
            if (!val) this.selectedCompetence = null;
            EventBus.$emit('DRAWER_STATE', val);
        },
        setChipColor(total, valid) {
            const half = Math.ceil(+total / 2);
            if (+valid >= half) {
                return 'green lighten-3';
            } else {
                return 'red lighten-3';
            }
        },
        setBorderColor(status) {
            const borders = {
                "Non évalué": "border: 1px solid blue !important; background-color: white !important;",
                "Acquis": "border: 1px solid green !important;",
                "Partiel": "border: 1px solid orange !important;",
                "Non acquis": "border: 1px solid red !important;",
                "--": "",
            }
            return borders[status];
        },
        setTextClass(status) {
            const classes = {
                "Non évalué": "blue--text",
                "Acquis": "green--text",
                "Partiel": "orange--text",
                "Non acquis": "red--text",
                "--": "black--text",
            }
            return classes[status];
        },
        setIcon(status) {
            const icons = {
                "Non évalué": "",
                "Acquis": "mdi-check-circle-outline",
                "Partiel": "",
                "Non acquis": "mdi-close-circle-outline",
                "--": "",
            }
            return icons[status];
        },
        async setGeneralComment() {
            this.loadingBtn = true
            try {
                const request = await apiService.post(`/evaluations/responses?target=comment`, {
                    userEval: this.$route.params.evaluationId,
                    comment: this.displayedComment,
                })
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit('OPEN_SNACKBAR', { message: response.message, isError: false });
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingBtn = false
            }
        },
        openReEvaluationDialog(item) {
            this.reEvaluateDialog = true
            this.selectedEvalCompetence = item;
        },
        async fetchEvaluationInfo() {
            this.loading = true;
            try {
                const authStore = useAuthStore();
                this.infoRole = authStore.info.role;

                let organization = this.infoRole == "superadmin" ? localStorage.getItem('organizationId'):   authStore.info.organization.id;
                const request = await apiService.get(`/organizations/${this.$route.params.organizationId ?? organization}/evaluations/${this.$route.params.evaluationId}/learners/${this.$route.params.learnerId ?? authStore.info.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.evaluation = response.currentEval;
                this.tutorEval = response.currentTutorEval;
                this.learner = response.currentEval.learner;

                const availableEvaluations = [];
                response.otherEvals.forEach(evalu => {
                    if (evalu.evaluation) {
                        evalu.evaluation.selected = false
                        availableEvaluations.push(evalu.evaluation);
                    }
                })

                this.evaluations = availableEvaluations;
                const selectedEval = availableEvaluations.find(el => el.id == this.$route.params.evaluationId)

                this.completed_by_learner = this.evaluation.is_completed
                this.titlePart = `${this.learner.lastname} ${this.learner.firstname} ${this.evaluation.label}`
                this.titlePart = this.titlePart.trim();
                this.titlePart = this.titlePart.replaceAll(" ", "_")
                this.titlePart = this.titlePart.replaceAll(".", "")

                if (selectedEval) {
                    selectedEval.selected = true;
                    this.selectedEvaluation = selectedEval;
                    this.selectedEvaluation.competences = response.currentEval.evaluationModel.competences.length
                    this.isCompleted =  Boolean(response.currentEval.is_completed)
                    this.isPersonnalized = response.currentEval.evaluationModel.is_personnalized

                    this.evalByTutor = response.competences.filter(el => !el.isSelf);
                    this.evalByUser = response.competences.filter(el => el.isSelf);

                    this.validCompetenceByTutor = this.evalByTutor.filter(el => el.status == "Acquis" && el.competence.is_parent).length
                    this.validCompetenceByLearner = this.evalByUser.filter(el => el.status == "Acquis" && el.competence.is_parent).length
                    this.validCompetenceCount = this.evalByTutor.filter(el => el.status == "Acquis" && el.competence.is_parent).length
                }

            } catch (error) {
                this.goBack();
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        },
        async refreshData() {
            await this.fetchEvaluationInfo()
        },
        setEnableValue() {
            let updated = this.competences.find(el => el.id == this.selectedEvalCompetence.id)
            updated.isEnable = true;
            updated.status = "Non évalué";
            updated.history = this.evaluation.history
        },
        setSelectedEvalCompetence(item) {
            this.evalDialog = true;
            this.selectedEvalCompetence = item;
        },
        resetValue() {
            this.selectedEvalCompetence = null;
        },
        async updateEnableState() {
            try {
                const request = await apiService.put(`/evaluations/${this.$route.params.evaluationId}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.defaultDisable = true;
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
        async restoreHandler() {
            try {
                const request = await apiService.put(`/evaluations/${this.$route.params.evaluationId}?target=restore`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$router.push({ name: this.$route.name, params: { evaluationId: this.$route.params.evaluationId } })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
        async softDeleteHandler() {
            try {
                const request = await apiService.delete(`/evaluations/${this.$route.params.evaluationId}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$router.push({ name: this.$route.name, params: { evaluationId: this.$route.params.evaluationId }, query: { archived: true } })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
        async hardDeleteHandler() {
            try {
                const request = await apiService.delete(`/evaluations/${this.$route.params.evaluationId}?target=confirm`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$router.push({ name: 'evaluations', replace: true })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
    }
};

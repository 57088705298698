import {
    EventBus
} from "@/evt-bus";
import {
    useAuthStore
} from "@/stores/AuthStore";
import apiService from "@/services/apiService";
import CreateFormTemplateFieldDialog from "@/components/dialog/CreateFormTemplateFieldDialog.vue";
import AssignParentCompetenceDialog from "@/components/dialog/AssignParentCompetenceDialog.vue";
import RestoreOrDeleteDialog from "@/components/dialog/RestoreOrDeleteDialog.vue";
import CompetenceCommentsDrawer from "@/components/drawer/CompetenceCommentsDrawer.vue";
import { formMixin } from '@/utils/formMixin';

export default {
    setup() { },

    components: {
        CreateFormTemplateFieldDialog,
        AssignParentCompetenceDialog,
        RestoreOrDeleteDialog,
        CompetenceCommentsDrawer
    },
    mixins: [formMixin],
    props: {},
    data() {
        return {
            loading: false,
            formTitle: '',
            isArchived: false,
            createFieldDialog: false,
            valid: false,
            isValided: false,
            parentCompetence: null,
            subCompetences: null,
            isCompetenceAssignable: true,
            competenceToAssigned: null,
            role: null,
            isNewForm: false,
            backupCompetences: [],

            openedDrawer: false,
            updateCommentDrawer: false,
            isAuthorizedToComment: false,
            competenceId: null,
            formTemplateId: null,

            isEdited: false,

        }
    },
    watch: {
        '$route'() {
            this.refreshInterface()
        }
    },
    async created() {
        const authStore = useAuthStore();
        this.role = authStore.info.role;
        this.refreshInterface()
    },

    mounted() { },

    methods: {
        setEditable(){
            this.isEdited = !this.isEdited;
        },
        async refreshInterface() {
            this.loading = true;
            await this.retrieveCompetenceToBeAssigned();
            if (this.$route.params.id) {
                await this.loadFormConfig(this.$route.params.id);
                await this.verifyAuthorizationToComment();
                this.formTemplateId = this.$route.params.id
            }
    
            if (!this.$route.params.id && this.$route.params.competenceId) {
                this.isCompetenceAssignable = false
            }
    
            if (!this.$route.params.id) {
                this.isNewForm = true;
            } else {
                this.isNewForm = false;
            }
            this.loading = false;
        },
        goBack() {
            this.$router.go(-1)
        },
        openCreateFieldDialog() {
            this.createFieldDialog = true;
        },
        selectedParentCompetence(selected) {
            this.parentCompetence = selected;
        },
        confirmed(selected) {
            this.backupCompetences = selected;
        },
        canceled() {
            this.parentCompetence = this.backupCompetences;
        },
        showComments() {
            !this.openedDrawer ? this.openedDrawer = true : this.openedDrawer = false
        },
        async loadFormConfig(id) {
            try {
                let target = ""
                if (this.$route.query.archived) target = "?target=trash"
                const response = await apiService.get(`/forms/${id}${target}`);
                const responseData = await response.json();
                this.formTitle = responseData.data.label;
                let structure = responseData.data.structure;
                this.isValided = responseData.data.is_validated;
                this.isArchived = responseData.data.isArchived

                if (typeof structure === 'string') {
                    structure = JSON.parse(structure);
                }

                this.additionnalFields = [];

                if (Array.isArray(structure)) {
                    structure.forEach(async field => {
                        await this.setFormStructure(field);
                    });
                }
            } catch (error) {
                console.error('Failed to load form configuration:', error);
            }
        },
        async verifyAuthorizationToComment() {
            const authStore = useAuthStore();
            this.currentUser = authStore.info;
            let organization = authStore.info.organization;

            if (this.creator != null) {
                this.isAuthorizedToComment = (organization != null && organization.id == this.creator.organization.id) ? true : false;
            } else {
                this.isAuthorizedToComment = (organization != null && organization.id == this.currentUser.organization.id) ? true : false;
            }
        },
        async retrieveCompetenceToBeAssigned() {
            try {
                this.items = [];
                const competenceId = this.$route.params.competenceId;
                const request = competenceId ? await apiService.get(`/competences/${competenceId}`) : await apiService.get(`/competences`);
                this.competenceId = competenceId
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                if (this.$route.params.competenceId && !this.$route.params.id) {
                    this.competenceToAssigned = response.data;
                }
                this.backupCompetences = [response.data];
                this.parentCompetence = [response.data];
                this.subCompetences = this.parentCompetence.children;

            } catch (error) {
                this.goBack();
            }
        },
        async submit() {
            try {
                this.valid = await this.isFormLabelsValid();
                if (!this.valid) return;

                let competenceId = (this.$route.params.id && this.$route.params.competenceId != this.parentCompetence[0].id) ? this.parentCompetence[0].id : this.$route.params.competenceId
                if (!competenceId && this.parentCompetence[0] != null) { competenceId = this.parentCompetence[0].id }
                const payload = {
                    label: this.formTitle,
                    is_validated: this.isValided,
                    competence_id: competenceId,
                    structure: this.additionnalFields
                }

                const request = await apiService.post(this.$route.params.id != null ? `/forms/${this.$route.params.id}` : '/forms', payload);
                const response = await request.json();
                if (!request.ok) {
                    EventBus.$emit("OPEN_SNACKBAR", {
                        message: response.message,
                        isError: true
                    });
                    throw new Error(response.message || "Veuillez réessayer ultérieurement");
                }

                if (this.$route.params.id != null) await this.loadFormConfig(this.$route.params.id)
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: response.message,
                    isError: false
                });
                this.goBack();
            } catch (error) {
                console.error('Failed to submit form:', error);
            }
        },
        async updateEnableState() {
            try {
                const request = await apiService.put(`/forms/${this.$route.params.id}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.defaultDisable = true;
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
        async restoreHandler() {
            try {
                const request = await apiService.put(`/forms/${this.$route.params.id}?target=restore`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$router.push({ name: this.$route.name, params: { competenceId: this.$route.params.competenceId, id: this.$route.params.id }, replace: true })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
        async softDeleteHandler() {
            try {                
                const request = await apiService.delete(`/forms/${this.$route.params.id}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$router.push({ name: this.$route.name, params: { competenceId: this.$route.params.competenceId, id: this.$route.params.id }, query: { archived: true }, replace: true })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
        async hardDeleteHandler() {
            try {
                const request = await apiService.delete(`/forms/${this.$route.params.id}?target=confirm`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$router.push({ name: 'evaluations', replace: true })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
    }
}
import { EventBus } from "@/evt-bus";
import apiService from "@/services/apiService";
import CreateUpdatePriviledgeUserDialog from "@/components/dialog/CreateUpdatePriviledgeUserDialog.vue";
import DeletePriviledgeUserDialog from "@/components/dialog/DeletePriviledgeUserDialog.vue";

export default {
    setup() { },

    components: {
        CreateUpdatePriviledgeUserDialog,
        DeletePriviledgeUserDialog,
    },

    props: {
        refreshItems: {
            default: false
        }
    },

    watch: {
        refreshItems(val) {
            if (val) this.retrieveList();
        },
        selectedFilter(val) {
            if (this.loading) return;
            this.currentPage = 1;
            const query = this.search == null ? { filter: val.value } : { search: this.search, filter: val.value };
            this.$router.push({ name: this.$route.name, query, replace: true }).catch(() => {})
            this.retrieveList();

        },
    },

    data() {
        return {
            items: [],
            search: '',
            loading: true,
            headers: [
                {
                    text: 'Noms',
                    align: 'start',
                    filterable: false,
                    value: 'lastname',
                },
                { text: 'Prénoms', value: 'firstname' },
                { text: 'Emails', value: 'email' },
                { text: 'Organismes', value: 'organization.label' },
                { text: 'Roles', value: 'role' },
                { text: 'Archivé', value: 'isArchived' },
                { text: 'Statuts', value: 'isEnable' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            filters: [
                { label: 'Trier de A à Z', value: 'asc' },
                { label: 'Trier de Z à A', value: 'desc' },
                { label: 'Trier par : Plus récent', value: 'recent' },
            ],
            deleteDialog: false,
            createUpdateDialog: false,
            selectedFilter: { label: 'Trier de A à Z', value: 'asc' },
            selectedItem: null,
        }
    },

    created() {
        const selected = this.filters.find(el => el.value == this.$route.query.filter)
        this.selectedFilter = selected ? selected : { label: 'Trier de A à Z', value: 'asc' };
        this.retrieveList();
    },

    mounted() { },

    methods: {
        openDeleteDialog(selected) {
            this.selectedItem = selected;
            this.deleteDialog = true;
        },

        openUpdateDialog(selected) {
            this.selectedItem = selected;
            this.createUpdateDialog = true;
        },

        async retrieveList() {
            const queries = [];
            this.loading = true;
            try {
                if (this.currentPage != 1) queries.push(`page=${this.currentPage}`)
                if (this.search != null && this.search.trim() != "") queries.push(`search=${this.search}`)
                if (this.selectedFilter.value != null) queries.push(`filter=${this.selectedFilter.value}`)
                
                let queryString = queries.join('&');
                const request = await apiService.get(`/priviledged/accounts?${queryString}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.items = response.data;
                this.$emit("refresh", false)
            } catch (error) {
                this.items = []
            } finally {
                this.loading = false;
            }
        },

        async toggleEnableItem(selected) {
            try {
                const request = await apiService.put(`/priviledged/accounts/${selected.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },

        async deleteItem() {
            try {
                const request = await apiService.delete(`/priviledged/accounts/${this.selectedItem.id}`);
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.retrieveList();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
    }
}
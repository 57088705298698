import { useAuthStore } from "@/stores/AuthStore"

export default {
    setup() {

    },

    components: {

    },

    props: {
        id: {
            default: ""
        },
        label: {
            default: ""
        },
        createdAt: {
            default: ""
        },
        isActive: {
            default: false
        },
        disabled: {
            default: false
        },
        role: {
            default: ''
        }, 
        referent: {
            default: null
        },
        learners: {
            default: null
        }
    },

    watch: {

    },

    data() {
        return {
            items: [],
        }
    },

    created() {
        const authStore = useAuthStore()
        this.currentUserRole = authStore.info.role;
        if (authStore.info.role == "admin" || authStore.info.role == "superadmin" ) {
            if (this.isActive) {
                    this.items = [
                        { title: 'Modifier', action: 'update' },
                        { title: 'Apprenants', action: 'assignLearners' },
                        { title: 'Référentiels', action: 'assignSkillBase' },
                        { title: 'Désactiver', action: 'disable' },
                    ]
            }else {
                this.items = [
                    { title: 'Restaurer', action: 'restore' },
                ]
            }
        } else if (authStore.info.role == "referent"){
            this.items = [
                { title: 'Référentiels', action: 'assignSkillBase' },
            ]
        }
    },

    mounted() {

    },

    methods: {
        actionHandler({ action }) {
            if (this.disabled) return;
            this.$emit(action, { promotionId: this.id })
        }
    },
}
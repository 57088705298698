import apiService from "@/services/apiService"
import { useAuthStore } from "@/stores/AuthStore"

export default {
    setup() {

    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        promoLearners: {
            default() {
                return []
            }
        },
        learners: {
            default() {
                return []
            }
        },
        tutor: {
            default() {
                return {}
            }
        },
        promo: {
            default() {
                return {}
            }
        }
    },

    watch: {
        async dialog(val) {
            if (val) await this.fetchLearners()
        }
    },

    data() {
        return {
            loading: true,
            items: [],
            search: null,
            headers: [
                { text: 'Noms', align: 'start', sortable: false, value: 'lastname', },
                { text: 'Prénoms', value: 'firstname', },
                { text: 'Emails', value: 'email', },
                { text: 'Actions', value: 'actions' },
            ],
        }
    },

    created() {
    },

    mounted() {

    },

    methods: {
        close() {
            this.$emit("update:dialog", false)
        },
        toogleSelection(item) {
            if (typeof item.selected === 'undefined') {
                this.$set(item, 'selected', true);
            } else {
                item.selected = !item.selected;
            }
        },
        async fetchLearners() {
            this.loading = true
            const authStore = useAuthStore();
            try {
                const url = authStore.info.organization != null ? `/organizations/${authStore.info.organization.id}/members?target=all&type=learners` : `/organizations/${this.promo.organization.id}/members?target=all&type=learners`
                const request = await apiService.get(url)
                const response = await request.json();

                if (!request.ok) throw new Error(response.message);
                let promoLearnerIds = this.promoLearners.map(learner => learner.id);
                let itemData = response.data.filter(el => promoLearnerIds.includes(el.id));

                itemData.forEach(el => {
                    el.selected = false;
                })

                this.learners.forEach(el => {
                    let element = itemData.find(el2 => el2.id == el.id)
                    if (element) element.selected = true;
                })
                this.items = itemData;
            } catch (error) {
                this.items = [];
            } finally {
                this.loading = false
            }
        },
        actionHandler() {
            const selectedItems = this.items.filter(el => el.selected)
            this.$emit('updateLearners', selectedItems)
            this.close();
        }
    },
}
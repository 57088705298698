import apiService from "@/services/apiService";
import CompetenceCard from "@/components/card/CompetenceCard.vue"

export default {
    setup() {

    },

    components: {
        CompetenceCard,
    },

    props: {},
    computed: {
        groupedItems() {
            const grouped = {};
            this.items.forEach((item) => {

                const catLabel = item.label;
                const catId = item.id;

                if (!grouped[catLabel]) {
                    grouped[catLabel] = [];
                }
                grouped[catLabel].push({ ...item, catId });

            });

            return grouped;
        },
    },

    watch: {
        '$route'() {
            this.retrieveList()
        },
        search(val) {
            if (this.loading) return;
            this.currentPage = 1;
            if (val == null || val.trim() == "") return this.$router.push({ name: this.$route.name, replace: true }).catch(() => { })
            this.$router.push({ name: this.$route.name, query: { search: val, filter: this.selectedFilter.value }, replace: true }).catch(() => { })
        },
        selectedFilter(val) {
            if (this.loading) return;
            this.currentPage = 1;
            const query = this.search == null ? { filter: val.value } : { search: this.search, filter: val.value };
            this.$router.push({ name: this.$route.name, query, replace: true }).catch(() => { })
        },
    },

    data() {
        return {
            total: 0,
            items: [],
            lastPage: 1,
            loading: true,
            currentPage: 1,
            showTrash: false,
            search: this.$route.query.search ?? null,
            selectedFilter: { label: 'Trier de A à Z', value: 'asc' },
            filters: [
                { label: 'Trier de A à Z', value: 'asc' },
                { label: 'Trier de Z à A', value: 'desc' },
                { label: 'Trier par : Plus récent', value: 'recent' },
            ],
            trashCompetences: [],
            competenceCount: 0
        };
    },

    created() {
        const selected = this.filters.find(el => el.value == this.$route.query.filter)
        this.selectedFilter = selected ? selected : { label: 'Trier de A à Z', value: 'asc' };
        this.retrieveList();
    },

    mounted() { },

    methods: {
        toggleTrash() {
            this.showTrash = !this.showTrash
            this.retrieveList();
        },
        async retrieveList() {
            this.loading = true;
            try {
                const queries = [];
                if (this.currentPage != 1) queries.push(`page=${this.currentPage}`)
                if (this.search != null && this.search.trim() != "") queries.push(`search=${this.search}`)
                if (this.selectedFilter.value != null) queries.push(`filter=${this.selectedFilter.value}`)

                let queryString = queries.join('&');
                this.showTrash ? queryString += '&target=competences-trashed' : queryString += '&target=competences';
                const url = `/categories?${queryString}`

                const request = await apiService.get(url)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                this.trashCompetences = response.data;
                this.items = response.data.filter(item => item.competences && item.competences.length > 0);

                this.total = response.total;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.panel = Array.from(Array(this.items.length).keys());

            } catch (error) {
                this.items = []
            } finally {
                this.loading = false;
            }
        },
        changePage() {
            this.retrieveList();
        }
    },
};
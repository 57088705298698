import { EventBus } from "@/evt-bus";
import PasswordScore from "@/components/notifications/PasswordScore.vue";
import apiService from "@/services/apiService";

export default {
    setup() { },

    components: {
        PasswordScore,
    },

    props: {},

    watch: {},

    data() {
        return {
            valid: true,
            loading: false,
            showPassword1: false,
            showPassword2: false,
            passwordScoreValid: false,

            password: null,
            passwordConfirm: null,
            rules: { required: value => !!value || 'Champs requis' },
        }
    },

    created() {

    },

    mounted() { },

    methods: {
        goToForgotten() {
            this.$router.push({ name: "forgottenAsk", replace: true })
        },

        passwordScoreHandler({ score }) {
            if (score < 2) this.passwordScoreValid = false;
            else this.passwordScoreValid = true;
        },

        async forgottenHandler() {
            this.loading = true;
            try {
                await this.$refs.form.validate();

                if (!this.valid || !this.passwordScoreValid) {
                    return EventBus.$emit("OPEN_SNACKBAR", {
                        message: "Le mot de passe n'est pas assez robuste.",
                        isError: true,
                    })
                }

                if (this.password != this.passwordConfirm) {
                    return EventBus.$emit("OPEN_SNACKBAR", {
                        message: "Les mots de passe ne sont pas identiques.",
                        isError: true,
                    })
                }

                const request = await apiService.post("/forgotten?target=reset", { code: this.$route.params.token, password: this.password, passwordConfirm: this.passwordConfirm });
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                this.$refs.form.reset()
                this.$refs.form.resetValidation();
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false })
                this.$router.push({ name: "login", replace: true })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement.",
                    isError: true,
                })
            } finally {
                this.loading = false
            }
        }
    }
}
import apiService from "@/services/apiService";
import { useAuthStore } from "@/stores/AuthStore";
import { EventBus } from "@/evt-bus";
import RestoreOrDeleteDialog from "@/components/dialog/RestoreOrDeleteDialog.vue"
import AssignCompetencesToEvaluationDialog from "@/components/dialog/AssignCompetencesToEvaluationDialog.vue"
import CreateFormTemplateFieldDialog from "@/components/dialog/CreateFormTemplateFieldDialog.vue";
import CompetenceWithFormTemplateChoiceCard from "@/components/card/CompetenceWithFormTemplateChoiceCard.vue";
import CompetenceTreeCards from "@/components/card/CompetenceTreeCards.vue";
import { formMixin } from '@/utils/formMixin';

export default {
    setup() { },

    components: {
        RestoreOrDeleteDialog,
        AssignCompetencesToEvaluationDialog,
        CreateFormTemplateFieldDialog,
        CompetenceWithFormTemplateChoiceCard,
        CompetenceTreeCards
    },
    mixins: [formMixin],
    props: {},

    watch: {
        '$route'() {
            this.refreshInterface()
        },
    },

    data() {
        return {
            role: '',
            loading: true,
            loadingBtn: false,
            isArchived: false,
            assignCompetencesDialog: false,
            valid: true,
            label: null,
            description: null,
            isEnabled: false,
            skillBase: null,
            skillBasesList: [],
            learners: [],
            competences: [],
            rules: { required: value => !!value || 'Champs requis', },
            defaultDisable: this.$route.params.id ? true : false,
            isEditing: this.$route.params.id ? true : false,
            evaluationId: this.$route.params.id ? true : false,
            creator: null,

            createFieldDialog: false,
            fieldToAdd: null,
            formTitle: '',
            formDescription: '',
            competenceTemplateFields: [],

        }
    },

    async created() {
        const authStore = useAuthStore();
        this.role = authStore.info.role;
        this.refreshInterface()
    },

    mounted() { },

    methods: {
        goBack() {
            this.$router.go(-1)
        },
        async refreshInterface() {
            if (this.$route.params.id) {
                await this.fetchSingleEvaluation();
            } else {
                if (this.role == 'referent') this.goBack();
                this.fetchSkillBases();
            }
        },
        skillBaseSelectDisplay(item) {
            if (this.role == 'superadmin'){
                return `# ${item.id} - ${item.promotion.organization.label} ${item.label} `
            }
            return `#${item.id} - ${item.label}`
        },
        updateCurrentData({ data, type }) {
            if (type == 'competences') this.competences = data;
        },
        updateCompetences(data) {
            this.competences = data;
        },
        async updateCompetenceRemoving(competences, competenceTemplateFields) {
           this.competences = competences ;
           this.competenceTemplateFields = competenceTemplateFields
           await this.actionHandler();
       },
        getCompetenceFormFields(competenceId) {
            return this.competenceTemplateFields.filter(field => field.competence_id === competenceId).map(field => field);
        },
        addFormTemplateToCompetence({  formTemplate }) {
            let competenceTemplateFields = this.competenceTemplateFields;
            if (typeof competenceTemplateFields === 'string') {
                competenceTemplateFields = JSON.parse(competenceTemplateFields);
            }

            if (formTemplate != null){
                const index = this.competenceTemplateFields.findIndex(el => el.competence_id === formTemplate[0].competence_id);
                if (index !== -1) {
                    this.$set(competenceTemplateFields, index, formTemplate[0]);
                } else {
                    this.competenceTemplateFields.push(formTemplate[0])
                }
            }
         
        },
        async fetchSkillBases() {
            this.loading = true;
            try {
                const request = await apiService.get(`/skill-bases?target=all`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.skillBasesList = response.data;
            } catch (error) {
                this.skillBasesList = [];
            } finally {
                this.loading = false;
            }
        },
        async fetchSingleEvaluation() {
            this.loading = true;
            try {
                let target = ""
                if (this.$route.query.archived) target = "?target=trash"
                const request = await apiService.get(`/evaluation-models/${this.$route.params.id}${target}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                this.label = response.data.label;
                this.isEnabled = response.data.isEnabled;
                this.skillBase = response.data.skillBase;
                this.isArchived = response.data.isArchived

                this.learners = [];
                this.competences = [];
                this.skillBasesList = [];

                this.skillBasesList.push(this.skillBase)

                response.data.competences.forEach(el => {
                    this.competences.push(el)
                })

                JSON.parse(response.data.competencesFields).forEach(el => {
                    this.competenceTemplateFields.push(el)
                })

                this.additionnalFields = []
                JSON.parse(response.data.evaluationFields).forEach(async field => {
                    await this.setFormStructure(field);
                });
            } catch (error) {
                this.goBack();
            } finally {
                this.loading = false;
            }
        },

        async actionHandler() {
            this.loadingBtn = true;
            try {
                await this.$refs.form.validate();
                if (!this.valid) return;

                const learnersToSend = [];
                const competencesToSend = [];

                this.learners.forEach(el => {
                    learnersToSend.push(el.id)
                })

                this.competences.forEach(el => {
                    competencesToSend.push(el.id)
                })

                     
                // Vérifier si tous les compétences parentes ont un modèle de formulaire
                const competenceTemplateIds = this.competenceTemplateFields.map(field => field.competence_id);
                const isParentCompetenceHasTemplate = this.competences.every(competence => competence.is_parent && competenceTemplateIds.includes(competence.id));

                
                if (!isParentCompetenceHasTemplate){
                    EventBus.$emit("OPEN_SNACKBAR", {
                        message: "Veuillez renseigner les modèles de formulaires pour les compétences parentes.",
                        isError: true,
                    })
                    return;
                }
                const url = this.$route.params.id ? `/evaluation-models/${this.$route.params.id}` : `/evaluation-models`
                const request = await apiService.post(url, {
                    label: this.label,
                    description: this.description,
                    skillBase: this.skillBase.id,
                    competences: competencesToSend,
                    learners: learnersToSend,
                    formTemplate: {
                        label: this.label,
                        is_validated: false,
                        entity_id: this.$route.params.evaluationId,
                        entity_type: 'evaluation',
                        structure: this.additionnalFields
                    },
                    competenceFormTemplates: this.competenceTemplateFields,
                    isEnabled: this.isEnabled
                })
                const response = await request.json();

                if (!request.ok) throw new Error(response.message);

                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })

                if (!this.$route.params.id) {
                    this.learners = [];
                    this.competences = [];
                    this.skillBasesList = [];
                    await this.$refs.form.reset();
                } else {
                    this.defaultDisable = true;
                }

            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingBtn = false
            }
        },
        async updateEnableState() {
            try {
                const request = await apiService.put(`/evaluation-models/${this.$route.params.id}?evalTemplate`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.defaultDisable = true;
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
    async restoreHandler() {
        try {
            const request = await apiService.put(`/evaluation-models/${this.$route.params.id}?target=restore`)
            const response = await request.json();
            if (!request.ok) throw new Error(response.message);
            EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
            this.$router.push({ name: this.$route.name, params: { id: this.$route.params.id } })
        } catch (error) {
            EventBus.$emit("OPEN_SNACKBAR", {
                message: error.message ?? "Veuillez réessayer ultérieurement",
                isError: true,
            })
        }
    },
    async softDeleteHandler() {
        try {
            const request = await apiService.delete(`/evaluation-models/${this.$route.params.id}`)
            const response = await request.json();
            if (!request.ok) throw new Error(response.message);
            EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
            this.$router.push({ name: this.$route.name, params: { id: this.$route.params.id }, query: { archived: true } })
        } catch (error) {
            EventBus.$emit("OPEN_SNACKBAR", {
                message: error.message ?? "Veuillez réessayer ultérieurement",
                isError: true,
            })
        }
    },
    async hardDeleteHandler() {
        try {
            const request = await apiService.delete(`/evaluation-models/${this.$route.params.evaluationId}?target=confirm`)
            const response = await request.json();
            if (!request.ok) throw new Error(response.message);
            EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
            this.$router.push({ name: 'evaluations', replace: true })
        } catch (error) {
            EventBus.$emit("OPEN_SNACKBAR", {
                message: error.message ?? "Veuillez réessayer ultérieurement",
                isError: true,
            })
        }
    },
}
}
import { EventBus } from "@/evt-bus";
import { useAuthStore } from "@/stores/AuthStore";

import apiService from "@/services/apiService";
import RestoreOrDeleteDialog from "@/components/dialog/RestoreOrDeleteDialog.vue"
import AssignCompetencesDialog from "@/components/dialog/AssignCompetencesDialog.vue"
import RemoveCompetenceFromSkillBaseDialog from "@/components/dialog/RemoveCompetenceFromSkillBaseDialog.vue"
import CreateTutorDialog from "@/components/dialog/CreateTutorDialog.vue";
import CreatePromotionsDialog from "@/components/dialog/CreatePromotionsDialog.vue";



export default {
    setup() {

    },

    components: {
        CreatePromotionsDialog,
        CreateTutorDialog,
        RestoreOrDeleteDialog,
        AssignCompetencesDialog,
        RemoveCompetenceFromSkillBaseDialog,
    },

    props: {},

    watch: {
        '$route'() {
            this.$router.go(0)
        },
        defaultDisable(val) {
            if(!val) {
                this.retrieveAvailablePromotions()
                this.retrieveAvailableTutors()
            }
        }
    },

    data() {
        return {
            valid: true,
            loading: false,
            loadingBtn: false,
            role: '',

            tabs: null,
            label: null,
            descriptions: null,
            owner: null,
            tutor: null,
            tutors: [],
            promotion: null,
            promotions: [],
            competences: [],
            backupCompetences: [],

            isEnabled: true,
            isArchived: false,
            isValided: false,
            rules: { required: value => !!value || 'Champs requis', },
            defaultDisable: this.$route.params.id ? true : false,
            isEditing: this.$route.params.id ? true : false,
            isVertical: true,
            user: null,
            dialogCompetenceToDelete: false,
            selectedCompetenceToDelete: null,
            skillBaseOrganization: null
        }
    },

    created() {
        const authStore = useAuthStore();
        this.role = authStore.info.role;
        this.user = authStore.info;

        if (this.$route.params.id) {
            this.retrieveSingle();
            this.retrieveAvailableTutors()
            this.retrieveAvailablePromotions()
        }

        this.retrieveAvailableTutors();
        this.retrieveAvailablePromotions();
        

        this.updateVerticalValue();
        window.addEventListener("resize", this.updateVerticalValue, { passive: true });
    },

    mounted() { },

    methods: {
        goBack() {
            this.$router.go(-1)
        },
        confirmed(selected) {
            this.backupCompetences = selected;
        },
        selected(selected) {
            this.competences = selected;
        },
        canceled() {
            this.competences = this.backupCompetences;
        },
        openDeleteCompetenceFromSkillBase(competence) {
            this.selectedCompetenceToDelete = competence
            this.dialogCompetenceToDelete = true;
        },
        resetSelected() {
            this.selectedCompetenceToDelete = null;
        },
        removeElement() {
            this.competences = this.competences.filter(el => el.id != this.selectedCompetenceToDelete.id)
            setTimeout(() => {
                if (this.competences.length <= 0) this.tabs = null;
                else this.tabs = 0;
            }, 10);
        },
        goToCompetenceDetail(item) {
            return this.$router.push({ name: "competenceForm", params: { id: item.id } })
        },
        updateVerticalValue() {
            if (window.innerWidth < 960) this.isVertical = false;
            else this.isVertical = true;
        },
        maxCharactere(desc) {
            if (desc.length > 18) desc = desc.slice(0, 18) + "..."
            return desc
        },
        tutorSelectDisplay(item) {
            return `#${item.id} - ${item.lastname} ${item.firstname}`
        },
        promotionSelectDisplay(item) {
            return `#${item.id} - ${item.label} - ${item.referent.lastname} ${item.referent.firstname}`
        },
        refreshAvailableTutors(){
            this.retrieveAvailableTutors()
        },
        async retrieveAvailableTutors() {
            try {
                const url = `/organizations/tutors`
                const request  = await apiService.get(url)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.tutors = response.data
            } catch (error) {
                this.tutors = []
            }
        },
        async retrieveAvailablePromotions() {
            try {
                const url = this.user.role != 'superadmin' ? `/organizations/promotion/${this.user.organization?.id}?target=all` : `/organizations/promotion?target=all`
                const request  = await apiService.get(url)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.promotions = response
            } catch (error) {
                this.tutors = []
            }
        },
        async retrieveSingle() {
            this.loading = true;
            try {
                let target = ""
                if (this.$route.query.archived) target = "?target=trash"
                const request = await apiService.get(`/skill-bases/${this.$route.params.id}${target}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.label = response.data.label
                this.tutor = response.data.createdBy
                this.tutors.push(this.tutor)
                this.promotion = response.data.promotion
                this.descriptions = response.data.descriptions
                this.isEnabled = response.data.isEnabled
                this.isArchived = response.data.isArchived
                this.isValided = response.data.isValided
                this.skillBaseOrganization = response.data.organization
                
                this.competences = [];
                for (let i = 0; i < response.data.competences.length; i++) {
                    const element = response.data.competences[i];
                    element.competence.selected = true;
                    this.competences.push(element.competence)
                }

                this.backupCompetences = this.competences;
            } catch (error) {
                this.goBack();
            } finally {
                this.loading = false;
            }
        },
        async actionHandler() {
            this.loadingBtn = true
            try {
                await this.$refs.form.validate();
                if (!this.valid) return;

                const selectedCompetences = [];
                for (let i = 0; i < this.competences.length; i++) {
                    const element = this.competences[i];
                    selectedCompetences.push(element.id);
                }

                let payload = {};
                
                payload = { label: this.label, description: this.descriptions ?? "", competences: selectedCompetences, promotionId: this.promotion.id, organizationId : this.promotion.organization.id }
            

                const url = this.$route.params.id ? `/skill-bases/${this.$route.params.id}` : `/skill-bases`
                const request = await apiService.post(url, payload)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })

                if (!this.$route.params.id) {
                    this.$refs.form.reset();
                    this.competences = [];
                    this.backupCompetences = [];
                } else {
                    this.defaultDisable = true;
                }
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingBtn = false
            }
        },
        async updateEnableState() {
            try {
                const request = await apiService.put(`/skill-bases/${this.$route.params.id}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.defaultDisable = true;
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
        async updateIsValidedState() {
            try {
                const request = await apiService.put(`/skill-bases/${this.$route.params.id}?target=valid`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: !this.isValided ? true : false, })
                this.defaultDisable = true;
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
        async restoreHandler() {
            try {
                const request = await apiService.put(`/skill-bases/${this.$route.params.id}?target=restore`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$router.push({ name: this.$route.name, params: { id: this.$route.params.id } })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
        async softDeleteHandler() {
            try {
                const request = await apiService.delete(`/skill-bases/${this.$route.params.id}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$router.push({ name: this.$route.name, params: { id: this.$route.params.id }, query: { archived: true } })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
        async hardDeleteHandler() {
            try {
                const request = await apiService.delete(`/skill-bases/${this.$route.params.id}?target=confirm`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$router.push({ name: 'competences', replace: true })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
    }
}
import { EventBus } from "@/evt-bus";
import { useAuthStore } from "@/stores/AuthStore"
import apiService from "@/services/apiService";
import ReferenceLibraryCard from "@/components/card/ReferenceLibraryCard";
import DeleteReferenceLibraryDialog from "@/components/dialog/DeleteReferenceLibraryDialog";

export default {
    setup() {

    },

    components: {
        ReferenceLibraryCard,
        DeleteReferenceLibraryDialog
    },

    props: {},
    computed: {
        groupedItems() {
            const grouped = {};

            this.items.forEach((item) => {
                item.organizations.forEach((org) => {
                    const orgLabel = org.organization.label;
                    const orgId = org.organization.id;

                    if (!grouped[orgLabel]) {
                        grouped[orgLabel] = [];
                    }
                    grouped[orgLabel].push({ ...item, orgId });
                });
            });

            return grouped;
        },
    },

    watch: {
        '$route'() {
            this.retrieveList()
        },
        search(val) {
            if (this.loading) return;
            this.currentPage = 1;
            if (val == null || val.trim() == "") return this.$router.push({ name: this.$route.name, replace: true }).catch(() => { })
            this.$router.push({ name: this.$route.name, query: { search: val.trim(), filter: this.selectedFilter.value.trim() }, replace: true }).catch(() => { })
        },
        selectedFilter(val) {
            if (this.loading) return;
            this.currentPage = 1;
            const query = this.search == null ? { filter: val.value } : { search: this.search.trim(), filter: val.value.trim() };
            this.$router.push({ name: this.$route.name, query, replace: true }).catch(() => { })
        },
    },

    data() {
        return {
            loading: true,
            showTrash: false,
            items: [],
            currentUserRole: '',
            filters: [
                { label: 'Trier de A à Z', value: 'asc' },
                { label: 'Trier de Z à A', value: 'desc' },
                { label: 'Trier par : Plus récent', value: 'recent' },
            ],
            search: this.$route.query.search ?? null,
            selectedFilter: { label: 'Trier de A à Z', value: 'asc' },
            total: 0,
            currentPage: 1,
            lastPage: 1,
            dialogReferenceLibraryDelete: false,
            id: 0

        }
    },

    created() {
        const authStore = useAuthStore()
        this.currentUserRole = authStore.info.role;
        
        const selected = this.filters.find(el => el.value == this.$route.query.filter)
        this.selectedFilter = selected ? selected : { label: 'Trier de A à Z', value: 'asc' };
        this.retrieveList();
    },

    mounted() { },

    methods: {
        openDeleteDialog(ids) {
            this.referenceId = ids[0]
            this.organizationId = ids[1]
            this.dialogReferenceLibraryDelete = true;
        },
        toggleTrash() {
            this.showTrash = !this.showTrash
            this.retrieveList();
        },
        async retrieveList() {
            this.loading = true
            try {
                const queries = [];
                if (this.currentPage != 1) queries.push(`page=${this.currentPage}`)
                if (this.search != null && this.search.trim() != "") queries.push(`search=${this.search}`)
                if (this.selectedFilter.value != null) queries.push(`filter=${this.selectedFilter.value}`)

                let queryString = queries.join('&');
                if (this.showTrash) queryString += '&target=trash'
                const url = `/reference-libraries?${queryString}`

                const request = await apiService.get(url)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.items = response.data;
                this.panel = Array.from(Array(this.items.length).keys());


                this.total = response.total;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
            } catch (error) {
                this.items = []
            } finally {
                this.loading = false;
            }
        },
        async removeReferenceLibraryAssignation(){
            await this.update();
        },
        setAssignationUpdateFormat() {
            // Find the reference to update by matching the referenceId
            let referenceToUpdate = this.items.find(el => el.id === this.referenceId);

            // Filter organizations to exclude the one specified by organizationId
            referenceToUpdate.organizations = referenceToUpdate.organizations
                .filter(org => org.organization.id !== this.organizationId)
                .map(org => org.organization.id);

            // Map skillBases to get the IDs of the assigned skillBases
            referenceToUpdate.skillBases = referenceToUpdate.skillBases
                .map(skillBase => skillBase.skillBase.id);

            return referenceToUpdate;
        },
        async update() {
            try {
                let referenceToUpdate = this.setAssignationUpdateFormat();
                const request = await apiService.post(`/reference-libraries/${this.referenceId}`, referenceToUpdate)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.retrieveList();

            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
        changePage() {
            this.retrieveList();
        }
    }
}
import { useAuthStore } from "@/stores/AuthStore";
import apiService from "@/services/apiService";

export default {
    setup() {

    },

    components: {

    },

    props: {
        dialog: {
            default: false
        },
        skillBase: {
            default() {
                return [];
            }
        },
        competences: {
            default() {
                return []
            }
        }
    },

    watch: {
        dialog(val) {
            if (val) {
                if (this.skillBase == null) return;
                this.getCompetenceParentList()
                this.competences.forEach(el => {
                    let element = this.items.find(el2 => el2.id == el.id)
                    if (element) {
                        this.$delete(element, 'selected'); 
                        this.$set(element, 'selected', true)
                    }
                })
            }
        }
    },

    data() {
        return {
            loading: true,
            items: [],
            competenceData: [],
            search: null,
            role: null,
            headers: [
                { text: 'Libellé', align: 'start', sortable: false, value: 'label', },
                { text: 'Actions', value: 'actions' },
            ],
        }
    },

    async created() {
        const authStore = useAuthStore();
        this.role = authStore.info.role;
            await this.getCompetenceParentList()
            this.competenceData = this.competences
        
    },
    mounted() {

    },
    methods: {
        close() {
            this.$emit("update:dialog", false)
        },
        toogleSelected(item) {
            item.selected = !item.selected
        },
        actionHandler() {
            const selectedItems = this.items.filter(el => el.selected)
            this.$emit('updateCompetences', selectedItems)
            this.close();
        },
        async getCompetenceParentList() {
            this.loading = true;
            try {
                const request = await apiService.get(`/competences?target=parent`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                this.items = response.data;

                if (this.skillBase != null) {
                    this.items = response.data.filter(item => 
                        item.skillBases.some(skillBase => skillBase.skill_base_id === this.skillBase.id)
                    );
                }
           
                 this.competences.forEach(el => {
                    let element = this.items.find(el2 => el2.id == el.id)
                    if (element) {
                        this.$delete(element, 'selected'); 
                        this.$set(element, 'selected', true);
                    }
                })

                this.items = this.items.sort((a, b) => {
                    if (a.selected) return -1;
                    if (b.selected) return 1;
                    return 0;
                });
            } catch (error) {
                this.items = [];
            } finally {
                this.loading = false;
            }
        }
    },
}
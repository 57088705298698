import UserInfoCard from "@/components/card/UserInfoCard.vue";
import ImportUsersDialog from "@/components/dialog/ImportUsersDialog.vue"
import UpdateUserDialog from "@/components/dialog/UpdateUserDialog.vue"
import DisableUserDialog from "@/components/dialog/DisableUserDialog.vue"
import RestoreOrDeleteUserDialog from "@/components/dialog/RestoreOrDeleteUserDialog.vue"
import apiService from "@/services/apiService";
import { EventBus } from "@/evt-bus";
import { useAuthStore } from "@/stores/AuthStore";

export default {
    setup() {

    },

    components: {
        UserInfoCard,
        ImportUsersDialog,
        UpdateUserDialog,
        DisableUserDialog,
        RestoreOrDeleteUserDialog,
    },

    props: {
        isArchived: {
            default: false,
        },
    },

    watch: {
        search(val) {
            if (val == null || val.trim() == "") this.retrieveLearners();
            if (this.loading) return;
            this.retrieveLearners();
        },
        updateUserDialog(val) {
            if (!val) {
                this.selectedUser = { id: null, lastname: null, firstname: null, email: null };
            }
        },
    },

    data() {
        return {
            total: 0,
            items: [],
            lastPage: 1,
            search: null,
            currentPage: 1,
            loading: true,
            importUserDialog: false,
            updateUserDialog: false,
            disableUserDialog: false,
            restoreUserDialog: false,
            isRestoring: false,
            showTrash: false,
            componentKey: 0,
            role: '',
            selectedUser: { id: null, lastname: null, firstname: null, email: null, promotion: null },
        }
    },

    created() {
        const authStore = useAuthStore();
        this.role = authStore.info.role;
        EventBus.$on('refreshLearners', () => {
            this.retrieveLearners();
        })

        this.retrieveLearners();

    },

    mounted() {
    },

    methods: {
        toggleTrash() {
            this.showTrash = !this.showTrash
            this.retrieveLearners();
        },
        async refreshComponent() {
            this.remountComponent()
            EventBus.$emit('refreshPromotions')
            EventBus.$emit('refreshTutors')
            await this.retrieveLearners();
        },
        async retrieveLearners() {
            this.loading = true;
            try {
                const authStore = useAuthStore();

                let target = 'untrash'
                if (this.showTrash) target = "trash"
                const url = (this.search == null || this.search.trim() == "")
                    ? `/organizations/${this.$route.params.id ?? authStore.info.organization.id}/members?target=${target}&type=learners&page=${this.currentPage}`
                    : `/organizations/${this.$route.params.id ?? authStore.info.organization.id}/members?target=${target}&type=learners&search=${this.search}&page=${this.currentPage}`

                const request = await apiService.get(url)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                this.items = response.data;
                this.total = response.total;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
            } catch (error) {
                this.items = [];
            } finally {
                this.loading = false;
            }
        },
        remountComponent() {
            this.componentKey += 1;
        },
        changePage() {
            this.retrieveLearners();
        },
        updateUserProfil({ userId }) {
            const selected = this.items.find(el => el.id == userId)
            this.selectedUser = selected;

            this.updateUserDialog = true;
        },
        disableUserProfil({ userId }) {
            const selected = this.items.find(el => el.id == userId)
            this.selectedUser = selected;
            this.disableUserDialog = true;
        },
        restoreUserProfil({ userId }) {
            const selected = this.items.find(el => el.id == userId)
            this.selectedUser = selected;
            this.restoreUserDialog = true;
            this.isRestoring = true;
        },
        deleteUserProfil({ userId }) {
            const selected = this.items.find(el => el.id == userId)
            this.selectedUser = selected;
            this.restoreUserDialog = true;
            this.isRestoring = false;
        },
        showUserEvaluations({ userId }) {
            const selected = this.items.find(el => el.id == userId)
            this.selectedUser = selected;
            const authStore = useAuthStore();
            if (authStore.info.role == 'admin' || authStore.info.role == 'superadmin') return this.$router.push({ name: "singleEvaluationProfil", params: { learnerId: selected.id, organizationId: this.$route.params.id } })
            if (authStore.info.role == 'tutor' || authStore.info.role == 'referent') return this.$router.push({ name: "evaluationProfil", params: { learnerId: selected.id } })
            return this.$router.push({ name: "singleEvaluation", params: { learnerId: selected.id } })
        }
    },
}
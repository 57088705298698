import UserInfoCard from "@/components/card/UserInfoCard.vue";
import ImportUsersDialog from "@/components/dialog/ImportUsersDialog.vue"
import UpdateUserDialog from "@/components/dialog/UpdateUserDialog.vue"
import DisableUserDialog from "@/components/dialog/DisableUserDialog.vue"
import RestoreOrDeleteUserDialog from "@/components/dialog/RestoreOrDeleteUserDialog.vue"
import AssignTutorPromotionDialog from "@/components/dialog/AssignTutorPromotionDialog.vue"
import AssignTutorSkillBasesDialog from "@/components/dialog/AssignTutorSkillBasesDialog.vue"
import apiService from "@/services/apiService";
import { EventBus } from "@/evt-bus";
import { useAuthStore } from "@/stores/AuthStore";

export default {
    setup() {

    },

    components: {
        UserInfoCard,
        ImportUsersDialog,
        UpdateUserDialog,
        DisableUserDialog,
        RestoreOrDeleteUserDialog,
        AssignTutorPromotionDialog,
        AssignTutorSkillBasesDialog,
    },

    props: {
        isArchived: {
            default: false,
        },
        isRefreshTutors: {
            default: false,
        }
    },

    watch: {
        search(val) {
            if(val == null || val.trim() == "") this.retrieveTutors();
            if (this.loading) return;
            this.retrieveTutors();
        },
        updateUserDialog(val) {
            if (!val) {
                this.selectedUser = { id: null, lastname: null, firstname: null, email: null , promotions: null};
            }
        },
    },

    data() {
        return {
            total: 0,
            items: [],
            lastPage: 1,
            search: null,
            currentPage: 1,
            loading: true,
            importUserDialog: false,
            updateUserDialog: false,
            disableUserDialog: false,
            restoreUserDialog: false,
            assignSkillBaseDialog: false,
            isRestoring: false,
            showTrash: false,
            selectedUser: { id: null, lastname: null, firstname: null, email: null },
        }
    },

    created() {
        EventBus.$on('refreshTutors', () => {
            this.retrieveTutors();
        })

        this.retrieveTutors();
    },

    mounted() {
    },

    methods: {
        toggleTrash() {
            this.showTrash = !this.showTrash
            this.retrieveTutors();
        },
        async refreshComponent() {
            EventBus.$emit('refreshLearners')
            EventBus.$emit('refreshPromotions')
            await this.retrieveTutors();
        },
        async retrieveTutors() {
            this.loading = true;
            try {
                const authStore = useAuthStore();

                let target = 'untrash'
                if(this.showTrash) target = "trash"
                const url = (this.search == null || this.search.trim() == "")
                    ? `/organizations/${this.$route.params.id ?? authStore.info.organization.id}/members?target=${target}&type=tutors&page=${this.currentPage}`
                    : `/organizations/${this.$route.params.id ?? authStore.info.organization.id}/members?target=${target}&type=tutors&search=${this.search}&page=${this.currentPage}`

                const request = await apiService.get(url)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                this.items = response.data;
                this.total = response.total;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
            } catch (error) {
                this.items = [];
            } finally {
                this.loading = false;
            }
        },
        changePage() {
            this.retrieveTutors();
        },
        updateUserProfil({ userId }) {
            const selected = this.items.find(el => el.id == userId)
            this.selectedUser = selected;
            this.updateUserDialog = true;
        },
        disableUserProfil({ userId }) {
            const selected = this.items.find(el => el.id == userId)
            this.selectedUser = selected;
            this.disableUserDialog = true;
        },
        restoreUserProfil({ userId }) {
            const selected = this.items.find(el => el.id == userId)
            this.selectedUser = selected;
            this.restoreUserDialog = true;
            this.isRestoring = true;
        },
        deleteUserProfil({ userId }) {
            const selected = this.items.find(el => el.id == userId)
            this.selectedUser = selected;
            this.restoreUserDialog = true;
            this.isRestoring = false;
        },
        assignSkillBase({ userId }) {
            const selected = this.items.find(el => el.id == userId)
            this.selectedUser = selected;
            this.assignSkillBaseDialog = true;
        }
    },
}
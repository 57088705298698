import { EventBus } from "@/evt-bus"
import apiService from "@/services/apiService"
import { useAuthStore } from "@/stores/AuthStore"

export default {
    setup() {

    },

    components: {

    },

    props: {
        dialog: {
            default: false,
        },
        currentLearners: {
            default: null
        },
        currentPromotion: {
            default: null
        }
    },

    watch: {
        dialog(val) {
            if (val) this.retrieveLearners()
        }
    },

    data() {
        return {
            loading: false,
            items: [],
            search: '',
            headers: [
                { text: 'Noms', align: 'start', sortable: false, value: 'lastname', },
                { text: 'Prénoms', value: 'firstname', },
                { text: 'Emails', value: 'email', },
                { text: 'Tuteurs', value: 'tutor', },
                { text: 'Actions', value: 'actions' },
            ],
        }
    },

    created() {
        this.retrieveLearners()
    },

    mounted() {

    },

    methods: {
        close() {
            this.search = '';
            this.items = []
            this.$emit("update:dialog", false)
        },
        toogleSelection(selected) {
            const element = this.items.find(el => el.id == selected.id)
            element.selected = !element.selected;
        },
        setLearnerToAssigned(data){
            let learners;
            if (this.currentLearners.length == 0){
                learners = data.filter(el => !el.tutor )        
            }else{
                learners = data.filter(el => 
                    !el.tutor || (el.tutor.tutor && el.tutor.tutor.id == this.currentPromotion.referent.id)
                );    
            }
            return learners;
        },
        selectLearners(data){
            this.currentLearners.forEach(el => {
                const updated = data.find(el2 => el2.id == el.id && (el2.tutor.tutor.id == this.currentPromotion.referent.id || el2.tutor.tutor.id == null))
                updated.selected = true;
            })
        },
        async retrieveLearners() {
            const authStore = useAuthStore();
            let data = null;
            this.items = [];

            try {
                const request = await apiService.get(`/organizations/${this.$route.params.id ?? authStore.info.organization.id}/members?target=all&type=learners`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                response.data.forEach(element => {
                    element.selected = false;
                });

                data = this.setLearnerToAssigned(response.data);
                if (this.currentLearners) this.selectLearners(data)
                this.items = data;
            } catch (error) {
                this.items = []
            }
        },
        async actionHandler() {
            this.loading = true;
            const authStore = useAuthStore();

            try {
                const data = this.items.filter(el => el.selected)
                const learners = [];
                data.forEach(el => {
                    learners.push(el.id)
                })

                const request = await apiService.post(`/assigns/promotion/learner`, {
                    "promoId": this.currentPromotion.id,
                    "tutorId":  this.currentPromotion.referent.id,
                    "organizationId": this.$route.params.id ?? authStore.info.organization.id,
                    "learners": learners
                })
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false })
                this.$emit('refresh')
                this.close();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false;
            }
        }
    },
}
import { EventBus } from "@/evt-bus"
import apiService from "@/services/apiService"
import { useAuthStore } from "@/stores/AuthStore";

export default {
    setup() { },

    components: {

    },

    props: {
        isFromSkillBase : false,
        skillBaseOrganization : null, 
    },

    watch: {
    
    },

    data() {
        return {
            dialog: false,
            valid: true,
            loading: false,
            lastname: null,
            firstname: null,
            email: null,
            user: null,
            role: "tutor",
            organizations: [],
            selectedOrganization : null,
            rules: {
                required: value => !!value || 'Champs requis',
                email: v => /.+@.+\..+/.test(v) || 'Adresse e-mail est invalide',
            },
        }
    },

    created() {
        const authStore = useAuthStore();
        this.user = authStore.info;
        if (this.user.role == "superadmin") {
            this.selectedOrganization = this.isFromSkillBase ? this.skillBaseOrganization : null
            this.retrieveAllOrganizations()
        }
        

    },

    mounted() { },

    methods: {
        close() {
            this.$refs.form.reset();
            this.dialog = false;
            this.$emit('canceled')
        },
        displayedLabel(item) {
            return `#${item.id} - ${item.label} - ${item.place}`
        },
        async retrieveAllOrganizations() {
            try {
                const request = await apiService.get(`/organizations?target=all`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.organizations = response.data

                if (this.selectedOrganization != null){
                    this.organizations = [this.selectedOrganization ]
                }

                if (!this.isFromSkillBase && this.$route.params.id){
                    this.organizations = this.organizations.filter(org  => org.id == this.$route.params.id)
                }

                if (this.user == null) {
                    this.selectedOrganization = response.data[0] ?? null;
                }
            } catch (error) {
                this.organizations = [];
            }
        },
        async actionHandler() {
            this.loading = true;
            try {
                await this.$refs.form.validate();
                if (!this.valid) return;
                const url = `/priviledged/accounts`;

                this.organization = this.user.role == "superadmin" ? this.selectedOrganization.id : this.user.organization.id
                const dataToSend =  { lastname: this.lastname, firstname: this.firstname, email: this.email, role: "tutor", organization: this.organization  }
                const request = await apiService.post(url, dataToSend)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$emit('refreshTutors')
                this.close();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false
            }
        },
    }
}
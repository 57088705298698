import { EventBus } from "@/evt-bus"
import apiService from "@/services/apiService"
import { useAuthStore } from "@/stores/AuthStore";
import CreateTutorDialog from "@/components/dialog/CreateTutorDialog.vue";

export default {
    setup() { },
    components: {
        CreateTutorDialog,

    },

    props: {
        canAddTutor: {
            default: false
        },
        isFromSkillBase: {
            default: false
        },
        skillBaseOrganization: {
            default : null,
        },
        defaultDisable:{
            default: false
        }
    
    },

    watch: {
    
    },

    data() {
        return {
            dialog: false,
            valid: true,
            loading: false,
            label: null,
            tutors: [],
            tutor: null,
            role: '',
            rules: {
                required: value => !!value || 'Champs requis',
                email: v => /.+@.+\..+/.test(v) || 'Adresse e-mail est invalide',
            },
        }
    },

    created() {
        const authStore = useAuthStore();
        this.user = authStore.info;
        this.retrieveAvailableTutors();
    },

    mounted() { },

    methods: {
        close() {
            this.$refs.form.reset();
            this.dialog = false;
            this.$emit('canceled')
        },
        tutorSelectDisplay(item) {
            return `#${item.id} - ${item.organization.label} ${item.lastname} ${item.firstname}`
        },
   
        async retrieveAvailableTutors() {
            try {
                const url = `/organizations/tutors`
                const request  = await apiService.get(url)
                const response = await request.json();

                if (!request.ok) throw new Error(response.message);
                if (this.$route.params.id && !this.isFromSkillBase){
                    this.tutors = response.data.filter(el => el.organization.id == this.$route.params.id)
                }else{
                    this.tutors = response.data
                }
            } catch (error) {
                this.tutors = []
            }
        },
        async actionHandler() {
            this.loading = true;
            try {
                await this.$refs.form.validate();
                if (!this.valid) return;
                const url = `/organizations/promotion`;

                let organization = this.user.role == 'superadmin' && this.isFromSkillBase ? this.skillBaseOrganization.id : this.$route.params.id ?? this.tutor.organization.id

                const dataToSend =  { label: this.label, tutorId : this.tutor.id, organizationId: organization}
                const request = await apiService.post(url, dataToSend)
                const response = await request.json();

                if (!request.ok) throw new Error(response.message);

                this.$emit("refresh")
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.close();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loading = false
            }
        },
    }
}
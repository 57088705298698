import apiService from "@/services/apiService";
import { useAuthStore } from "@/stores/AuthStore";

import RestoreOrDeleteDialog from "@/components/dialog/RestoreOrDeleteDialog.vue"
import AssignLearnersToEvaluationDialog from "@/components/dialog/AssignLearnersToEvaluationDialog.vue"
import AssignCompetencesToEvaluationDialog from "@/components/dialog/AssignCompetencesToEvaluationDialog.vue"
import CompetenceWithFormTemplateChoiceCard from "@/components/card/CompetenceWithFormTemplateChoiceCard.vue";
import CreateFormTemplateFieldDialog from "@/components/dialog/CreateFormTemplateFieldDialog.vue";
import CompetenceTreeCards from "@/components/card/CompetenceTreeCards.vue";

import { formMixin } from '@/utils/formMixin';
import { EventBus } from "@/evt-bus";
import { utilMixin } from "@/utils/utilMixin";

export default {
    setup() { },

    components: {
        RestoreOrDeleteDialog,
        AssignLearnersToEvaluationDialog,
        AssignCompetencesToEvaluationDialog,
        CompetenceWithFormTemplateChoiceCard,
        CreateFormTemplateFieldDialog,
        CompetenceTreeCards
    },
    mixins: [formMixin, utilMixin],

    props: {},

    watch: {
        '$route'() {
            this.$router.go(0)
        },

        evaluationModel(newVal) {
            if (newVal) {
                this.additionnalFields = []
                this.competenceTemplateFields = []
                JSON.parse(newVal.evaluationFields).forEach(async field => {
                    await this.setFormStructure(field);
                });
                this.competenceTemplateFields = JSON.parse(newVal.competencesFields)
                this.competences = newVal.competences
            }
        }
    },

    data() {
        return {
            role: '',
            loading: false,
            evaluationModelLoading: false,
            loadingBtn: false,
            isArchived: false,
            isEdited: false,
            assignLearnersDialog: false,
            assignCompetencesDialog: false,
            labelValid: true,
            valid: true,
            label: null,
            description: null,
            isEnabled: true,
            skillBase: null,
            skillBasesList: [],
            learners: [],
            competences: [],
            rules: { required: value => !!value || 'Champs requis', },
            defaultDisable: this.$route.params.evaluationId ? true : false,
            isEditing: this.$route.params.evaluationId ? true : false,
            evaluationId: this.$route.params.evaluationId ? true : false,
            creator: null,
            evaluationModel: null,
            evaluationModels: [],
            evaluationModelFilteredList: [],
            canPersonalizedLearnerEvaluation: false,
            selectedModel: null,
            childrenCompetenceTemplate: [],
            visibleCompetenceIds: [],
            competenceTemplateFields: [],
            tutors: [],
            promotion: null,
            promotions: [],
            assignedLearners: [],
            organization: {},
            componentKey: 0
        }
    },
    computed: {
        uniqueLearners() {
            return this.assignedLearners.filter((item, index, self) =>
                index === self.findIndex((t) => t.id === item.id)
            );
        },
    },
    async created() {
        const authStore = useAuthStore();
        this.organization = authStore.info.organization;
        this.role = authStore.info.role;

        if (this.$route.params.evaluationId) {
            this.fetchSingleEvaluation();
            this.canPersonalizedLearnerEvaluation = true;
        } else {
            this.isEditing = false;
            if (this.role == 'referent') this.goBack();
            await this.fetchPromotions()
        }
    },

    methods: {
        goBack() {
            this.$router.go(-1)
        },
        getFullName(item) {
            return `${item.firstname} ${item.lastname}`;
        },
        promotionSelectDisplay(item) {
            if (this.role == 'superadmin'){
                return `#${item.organization.label} -  ${item.label} - ${item.referent.lastname} ${item.referent.firstname}`
            }
            return `#${item.id} - ${item.label} - ${item.referent.lastname} ${item.referent.firstname}`
        },
        skillBaseDisplay(item) {
            return `#${item.id} - ${item.label} `
        },
        async personnalizedEvaluationLearner(learnerId) {
            const request = await apiService.get(`/organizations/${this.promotion.organization.id}/evaluations/${this.$route.params.evaluationId}/learners/${learnerId}`);
            const response = await request.json();
            if (!request.ok) throw new Error(response.message);

            this.item = response.currentEval;
            this.$router.push({
                name: "evaluationLearnerFormViewForTutor",
                params: { evaluationId: this.$route.params.evaluationId, learnerId: learnerId, evaluationModelId: this.item.evaluationModel.id, },
                query: {
                    source: 'personnalized'
                }
            })
        },
        updateCurrentData({ data, type }) {
            if (type == 'learners') this.learners = data;
            if (type == 'competences') this.competences = data;
        },
        updateLearners(data) {
            this.assignedLearners = data;
        },
        updateCompetences(data) {
            this.competences = data;
        },
        async updateCompetenceRemoving(competences, competenceTemplateFields) {
            this.competences = competences;
            this.competenceTemplateFields = competenceTemplateFields
        },
        openDeleteDialog(id) {
            this.toDeleteId = id
            this.dialogCompetenceDelete = true;
        },
        openAssignLearnerDialog() {
            if (this.learners.length != 0) {
                this.assignLearnersDialog = true
            } else {
                EventBus.$emit("OPEN_SNACKBAR", { message: "Veuillez sélectionner la promotion", isError: true, })
            }
        },
        async selectTemplatesBySkillBase(skillBase) {
            await this.fetchEvaluationModels()
            this.evaluationModel = null;
            if (this.$route.params.evaluationId) {
                this.evaluationModelFilteredList[0] = this.evaluationModels.filter(field => field.evaluation_id == null && field.isEnabled && field.skillBase.id == skillBase.id).map(field => field);
                this.evaluationModels = this.evaluationModelFilteredList[0]
            } else {
                if (this.evaluationModels.length > 0) {
                    this.evaluationModelFilteredList = this.evaluationModels[0].filter(field => field.evaluation_id == null && field.isEnabled && field.skillBase.id == skillBase.id).map(field => field);
                    this.evaluationModels = this.evaluationModelFilteredList[0]
                }
            }
        },
        async onPromoSelection(item) {
            this.skillBase = item.skillBase;
            item.learners.forEach(el => {
                this.learners.push(el.user)
            })

            await this.selectTemplatesBySkillBase(this.skillBase);
        },
        async onEvaluationTemplateChange() {
            if (this.evaluationModel != null) {
                this.childrenCompetenceTemplate = this.evaluationModel.competences;
                this.componentKey += 1;
            }
        },
        addFormTemplateToCompetence(formTemplate) {
            if (formTemplate != null) {
                const index = this.competenceTemplateFields.findIndex(el => el.competence_id === formTemplate.formTemplate[0].competence_id);
                if (index !== -1) {
                    this.$set(this.competenceTemplateFields, index, formTemplate.formTemplate[0]);
                } else {
                    this.competenceTemplateFields.push(formTemplate.formTemplate[0])
                }
            }
        },

        async fetchPromotions() {
            try {
                const request = await apiService.get((this.role != 'superadmin')  ? `/organizations/promotion/${this.organization.id}?target=all` : 
                `/organizations/promotion?target=all`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                this.promotions = response;
            } catch (error) {
                this.promotions = [];
            }
        },
        async fetchEvaluationModels() {
            this.evaluationModelLoading = true;
            try {
                const request = await apiService.get(`/evaluation-models`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                this.evaluationModels = [];
                response.data.forEach(data => {
                    if (data.evaluationModels != null) {
                        this.evaluationModels.push(data.evaluationModels)
                    }
                });

                if (this.$route.params.evaluationId) {
                    this.evaluationModelFilteredList = this.evaluationModels[0].filter(field => field.evaluation_id != this.$route.params.evaluationId && field.evaluation_id == null && !field.is_personnalized && field.isEnabled).map(field => field)
                } else {
                    this.evaluationModelFilteredList = this.evaluationModels[0].filter(field => field.evaluation_id == null && field.is_personnalized == 0 && field.isEnabled).map(field => field)
                }

            } catch (error) {
                this.evaluationModels = [];
            } finally {
                this.evaluationModelLoading = false;
            }
        },
        async fetchSingleEvaluation() {
            this.loading = true;
            try {
                let target = ""
                if (this.$route.query.archived) target = "?target=trash"
                const request = await apiService.get(`/evaluations/${this.$route.params.evaluationId}${target}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);

                this.label = response.data.label;
                this.isEnabled = response.data.isEnabled;
                this.description = response.data.description;
                this.skillBase = response.data.skillBase;
                this.isArchived = response.data.isArchived
                this.creator = response.data.createdBy;
                this.promotion = response.data.promotion;

                this.learners = [];
                this.assignedLearners = [];
                this.competences = [];

                response.data.learners.forEach(el => {
                    this.assignedLearners.push(el.learner)
                })

                this.assignedLearners = this.assignedLearners.filter(
                    (learner, index, self) =>
                        index === self.findIndex((l) => l.id === learner.id)
                );

                this.promotion.learners.forEach(el => {
                    this.learners.push(el.user)
                })

                await this.fetchEvaluationModels();
                this.existingEvaluationFields = []

                if (response.data.templates) {
                    this.evaluationModel = response.data.templates;
                    JSON.parse(response.data.templates.competencesFields).forEach(el => {
                        this.competenceTemplateFields.push(el)
                    })

                    this.parseData(response.data.templates.evaluationFields).forEach(async field => {
                        await this.setFormStructure(field);
                    });
                }

                this.evaluationModel == null ?
                    this.competences = response.data.competences :
                    this.competences = this.evaluationModel.competences

                this.childrenCompetenceTemplate = this.competences;
                const competences = response.data.competences.map(item => item.competence);
                this.childrenCompetenceTemplate = competences;

                if (this.$route.params.evaluationId) {
                    let currentEvalModel = this.evaluationModel;
                    this.evaluationModelFilteredList = this.evaluationModelFilteredList.filter(model => model.label !== currentEvalModel.label);
                    this.evaluationModelFilteredList.push(currentEvalModel);
                }

            } catch (error) {
                this.goBack();
            } finally {
                this.loading = false;
            }
        },
        async actionHandler() {
            this.loadingBtn = true;
            try {
                await this.$refs.form.validate();
                this.labelValid = await this.isFormLabelsValid();
                if (!this.valid || !this.labelValid) {
                    EventBus.$emit("OPEN_SNACKBAR", { message: "Veuillez compléter les champs requis", isError: true, })
                    return;
                }
                const learnersToSend = [];
                const competencesToSend = [];

                this.uniqueLearners.forEach(el => {
                    learnersToSend.push(el.id)
                })

                this.competences.forEach(el => {
                    competencesToSend.push(el.id)
                })

                const url = this.$route.params.evaluationId ? `/evaluations/${this.$route.params.evaluationId}` : `/evaluations`
                const request = await apiService.post(url, {
                    label: this.label,
                    description: this.description,
                    skillBase: this.skillBase.id,
                    tutor: this.creator ? this.creator.id : 0,
                    promotionId: this.promotion.id,
                    evaluation_form_template_id: this.evaluationModel.id,
                    competences: [...new Set(competencesToSend)],
                    learners: learnersToSend,
                    is_enabled: false,
                    structure_fields_evaluation: this.additionnalFields,
                    structure_fields_competence: this.competenceTemplateFields,
                })
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })

                if (!this.$route.params.evaluationId) {
                    this.learners = [];
                    this.competences = [];
                    this.skillBasesList = [];
                    this.existingEvaluationFields = [];
                    this.evaluationModels = [];
                    await this.$refs.form.reset();
                } else {
                    this.defaultDisable = true;
                }
                window.location.reload();
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            } finally {
                this.loadingBtn = false
            }
        },
        async updateEnableState() {
            try {
                const request = await apiService.put(`/evaluations/${this.$route.params.evaluationId}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.defaultDisable = true;
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
        async restoreHandler() {
            try {
                const request = await apiService.put(`/evaluations/${this.$route.params.evaluationId}?target=restore`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$router.push({ name: this.$route.name, params: { evaluationId: this.$route.params.evaluationId } })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
        async softDeleteHandler() {
            try {
                const request = await apiService.delete(`/evaluations/${this.$route.params.evaluationId}`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$router.push({ name: this.$route.name, params: { evaluationId: this.$route.params.evaluationId }, query: { archived: true } })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
        async hardDeleteHandler() {
            try {
                const request = await apiService.delete(`/evaluations/${this.$route.params.evaluationId}?target=confirm`)
                const response = await request.json();
                if (!request.ok) throw new Error(response.message);
                EventBus.$emit("OPEN_SNACKBAR", { message: response.message, isError: false, })
                this.$router.push({ name: 'evaluations', replace: true })
            } catch (error) {
                EventBus.$emit("OPEN_SNACKBAR", {
                    message: error.message ?? "Veuillez réessayer ultérieurement",
                    isError: true,
                })
            }
        },
    }
}